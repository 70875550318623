@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Noto+Serif+Thai:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Roboto";
  /* -ms-overflow-style: none; */
  /* user-select: none; */
}

body {
  background: #f7f8fa;
}

/* -------------------Common-------------------------- */
input,
select,
textarea {
  padding: 8px;
  background: white;
  border: 1px solid #d9d9d9;
  color: #2e2e2e;
  border-radius: 4px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  resize: none;
}

.tip-confirm-msg {
  padding: 8px;
  background: white;
  border: 1px solid #d9d9d9;
  color: #000000;
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  resize: none;
}

.common-input {
  color: #000000;
  background-color: white;
  width: 100%;
  height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  padding: 1px 8px 0px;
}

.hide-overflowX {
  overflow-x: hidden !important;
}

.hide-overflowX::-webkit-scrollbar {
  display: none !important;
}

p {
  margin-bottom: 0px !important;
}

.common-select {
  background: url("./assets/images/droparrow.svg") no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding: 4px 30px 4px 10px !important;
  overflow: hidden;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  background-color: white;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #2e2e2e;
  height: 27px;
  min-width: 125px;
}

.select-input {
  background: url("./assets/images/d-arrow.svg") no-repeat;
  background-position: calc(100% - -1px) center !important;
  width: 100%;
  height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  background-color: white;
  font-weight: 400;
}

.paginationselect {
  background: url("./assets/images/arrow.svg") no-repeat;
  margin: 0px 10px;
  min-width: 59px !important;
}

select,
textarea,
input:focus-visible {
  outline-offset: 0 !important;
  outline: 0 !important;
}

.hide-selectarrow {
  background: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
}

a {
  color: #000000;
}

.overflow-hide {
  overflow: hidden !important;
}

.pointer {
  cursor: pointer !important;
}

.pointer-default {
  cursor: default !important;
}

.link-p {
  text-decoration: none !important;
}

.link-p:hover {
  text-decoration: underline !important;
}

.listStyle-none {
  list-style: none !important;
  padding-left: 0px;
  font-weight: 400;
  font-size: 14px;
  color: #2e2e2e;
}

.bg-danger {
  border: 1px solid #d24343;
  background-color: #d24343;
  color: white !important;
}

.bg-blue {
  background: #2878fa !important;
}

.bg-lite-blue {
  background: #f0f6ff !important;
}

.bg-grey {
  background-color: #898989 !important;
}

.bg-success {
  background-color: #2cc56f !important;
}

.border-success {
  border: 1px solid #2cc56f !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-all {
  border: 1px solid #dee2e6 !important;
}

.text-success {
  color: rgb(1, 152, 1) !important;
}

.text-grey {
  color: #787878 !important;
}

.text-7D7E7E {
  color: #7D7E7E !important;
}

.text-liteGrey {
  color: #A6A6A6 !important;
}

.text-inactive {
  color: #d0d0d0 !important;
}

.select-placeholder {
  color: #aab7c4 !important;
}

.text-blue {
  color: #2878fa !important;
}

.text-dark-blue {
  color: #223342;
}

.text-black {
  color: black !important;
}

.success-status {
  max-width: 82px;
  padding: 5px 6px;
  color: #00ba34 !important;
  background: #e6f6e9;
  border-radius: 19px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ready-status {
  color: #ffa500 !important;
  max-width: 82px;
  padding: 5px 6px;
  background: rgba(255, 106, 156, 0.15);
  border-radius: 19px;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.pending-status {
  color: #ff9a00 !important;
  max-width: 82px;
  padding: 5px 6px;
  background: rgba(255, 176, 116, 0.15);
  border-radius: 19px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.danger-status {
  color: #d24343 !important;
  max-width: 82px;
  padding: 5px 6px;
  background: rgba(210, 67, 67, 0.15);
  border-radius: 19px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.circle-10px {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: none;
}

.color-success-status {
  color: #00ba34 !important;
}

.color-success-status-bg {
  background: #00ba34 !important;
}

.bg-success-payment {
  background: rgba(44, 197, 111, 0.15) !important;
  border: 1px solid #e6e8eb !important;
}

.color-ready-status {
  color: #ffa500 !important;
}

.color-ready-status-bg {
  background-color: #ffa500 !important;
}

.bg-ready-payment {
  background: #fff3ea !important;
}

.color-pending-status {
  color: #ff9a00 !important;
}

.color-pending-status-bg {
  background: #ff9a00 !important;
}

.bg-pending-payment {
  background: #fff3ea !important;
}

.color-danger-status {
  color: #d24343 !important;
}

.color-danger-status-bg {
  background: #d24343 !important;
}

.bg-danger-payment {
  background: rgba(210, 67, 67, 0.15) !important;
}

.camal-case {
  text-transform: capitalize !important;
}

.img-transform-45 {
  transform: rotate(45deg);
}

.img-transform-180 {
  transform: rotate(180deg);
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.my-29px {
  margin-top: 29px !important;
  margin-bottom: 29px !important;
}

.p-more-option {
  /* padding: 11px 0px 9px 0px !important; */
}

.px-0px {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.px-5px {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.px-7px {
  padding-left: 7px !important;
  padding-right: 7px !important;
}

.px-10px {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.px-12px {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.px-15px {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.px-20px {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.px-27px {
  padding-left: 27px !important;
  padding-right: 27px !important;
}

.px-37px {
  padding-left: 37px !important;
  padding-right: 37px !important;
}

.py-2px {
  padding-top: 2.4px !important;
  padding-bottom: 2.4px !important;
}

.py-5px {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.py-6px {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.py-8px {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.py-10px {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.py-13px {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}

.py-29px {
  padding-top: 29px !important;
  padding-bottom: 29px !important;
}

.py-30px {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.py-40px {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.p-0px {
  padding: 0px !important;
}

.p-6px {
  padding: 6px !important;
}

.p-9px {
  padding: 9px !important;
}

.p-10px {
  padding: 10px !important;
}

.p-11px {
  padding: 11px !important;
}

.p-12px {
  padding: 12px !important;
}

.p-14px {
  padding: 14px !important;
}

.p-40px {
  padding: 40px !important;
}

.pt-1px {
  padding-top: 1px !important;
}

.pt-2px {
  padding-top: 2px !important;
}

.pt-3px {
  padding-top: 3px !important;
}

.pt-5px {
  padding-top: 5px !important;
}

.pt-6px {
  padding-top: 6px !important;
}

.pt-7px {
  padding-top: 7px !important;
}

.pt-9px {
  padding-top: 9px !important;
}

.pt-10px {
  padding-top: 10px !important;
}

.pt-11px {
  padding-top: 11px !important;
}

.pt-15px {
  padding-top: 15px !important;
}

.pt-17px {
  padding-top: 17px !important;
}

.pt-18px {
  padding-top: 18px !important;
}

.pt-20px {
  padding-top: 20px !important;
}

.pt-21px {
  padding-top: 21px !important;
}

.pt-22px {
  padding-top: 22px !important;
}

.pt-19px {
  padding-top: 19px !important;
}

.pt-50px {
  padding-top: 50px !important;
}

.pt-90px {
  padding-top: 90px !important;
}


.pt-0px {
  padding-top: 0px !important;
}

.pt-05px {
  padding-top: 0.5px !important;
}

.pb-0px {
  padding-bottom: 0px !important;
}

.pb-2px {
  padding-bottom: 2px !important;
}

.pb-5px {
  padding-bottom: 5px !important;
}

.pb-10px {
  padding-bottom: 10px !important;
}

.pb-11px {
  padding-bottom: 11px !important;
}

.pb-12px {
  padding-bottom: 12px !important;
}

.pb-14px {
  padding-bottom: 14px !important;
}

.pb-30px {
  padding-bottom: 30px !important;
}

.pb-33px {
  padding-bottom: 33px !important;
}

.pb-51px {
  padding-bottom: 51px !important;
}

.pb-62px {
  padding-bottom: 62px !important;
}

.pb-99px {
  padding-bottom: 99px !important;
}

.mx-5px {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.mx-10px {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mx-18px {
  margin-left: 18px !important;
  margin-right: 18px !important;
}

.mt-0px {
  margin-top: 0px !important;
}

.mt-2px {
  margin-top: 2px !important;
}

.mt-3px {
  margin-top: 3px !important;
}

.mt-4px {
  margin-top: 4px !important;
}

.mt-5px {
  margin-top: 5px !important;
}

.mt-6px {
  margin-top: 6px !important;
}

.mt-7px {
  margin-top: 7px !important;
}

.mt-8px {
  margin-top: 8px !important;
}

.mt-10px {
  margin-top: 10px !important;
}

.mt-11px {
  margin-top: 11px !important;
}

.mt-12px {
  margin-top: 12px !important;
}

.mt-14px {
  margin-top: 14px !important;
}

.mt-15px {
  margin-top: 15px !important;
}

.mt-18px {
  margin-top: 18px !important;
}

.mt-19px {
  margin-top: 19px !important;
}

.mt-20px {
  margin-top: 20px !important;
}

.mt-21px {
  margin-top: 21px !important;
}

.mt-22px {
  margin-top: 22px !important;
}

.mt-25px {
  margin-top: 25px !important;
}

.mt-30px {
  margin-top: 30px !important;
}

.mt-33px {
  margin-top: 33px !important;
}

.mt-34px {
  margin-top: 34px !important;
}

.mt-35px {
  margin-top: 35px !important;
}

.mt-38px {
  margin-top: 38px !important;
}

.mt-45px {
  margin-top: 45px !important;
}

.mt-49px {
  margin-top: 49px !important;
}

.mt-51px {
  margin-top: 51px !important;
}

.mt-53px {
  margin-top: 53px !important;
}

.mt-55px {
  margin-top: 55px !important;
}

.mt-60px {
  margin-top: 60px !important;
}

.mt-73px {
  margin-top: 73px !important;
}

.mt-90px {
  margin-top: 90px !important;
}

.mt-94px {
  margin-top: 94px !important;
}

.mt-98px {
  margin-top: 98px !important;
}

.mt-100px {
  margin-top: 100px !important;
}

.mt-101px {
  margin-top: 101px !important;
}

.mt-121px {
  margin-top: 121px !important;
}

.mt-140px {
  margin-top: 140px !important;
}

.mt-150px {
  margin-top: 150px !important;
}

.mt-171px {
  margin-top: 171px !important;
}

.mt-225px {
  margin-top: 225px !important;
}

.mt-m1px {
  margin-top: -1px !important;
}

.mt-m22px {
  margin-top: -22px;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-2px {
  margin-bottom: 2px !important;
}

.mb-5px {
  margin-bottom: 5px !important;
}

.mb-6px {
  margin-bottom: 6.13px !important;
}

.mb-7px {
  margin-bottom: 7px !important;
}

.mb-8px {
  margin-bottom: 8px !important;
}

.mb-10px {
  margin-bottom: 10px !important;
}

.mb-11px {
  margin-bottom: 11px !important;
}

.mb-12px {
  margin-bottom: 12px !important;
}

.mb-13px {
  margin-bottom: 13px !important;
}

.mb-15px {
  margin-bottom: 15px !important;
}

.mb-17px {
  margin-bottom: 17px !important;
}

.mb-18px {
  margin-bottom: 18px !important;
}

.mb-19px {
  margin-bottom: 19px !important;
}

.mb-20px {
  margin-bottom: 20px !important;
}

.mb-22px {
  margin-bottom: 22px !important;
}

.mb-23px {
  margin-bottom: 23px !important;
}

.mb-27px {
  margin-bottom: 27px !important;
}

.mb-29px {
  margin-bottom: 29px !important;
}

.mb-30px {
  margin-bottom: 30px !important;
}

.mb-34px {
  margin-bottom: 34px !important;
}

.mb-35px {
  margin-bottom: 35px !important;
}

.mb-40px {
  margin-bottom: 40px !important;
}

.mb-45px {
  margin-bottom: 45px !important;
}

.mb-47px {
  margin-bottom: 47px !important;
}

.mb-49px {
  margin-bottom: 49px !important;
}

.mb-78px {
  margin-bottom: 78px !important;
}

.mb-100px {
  margin-bottom: 100px !important;
}

.mb-rem04 {
  margin-bottom: 0.4rem !important;
}

.gap-12px {
  gap: 12px !important;
}

.gap-6px {
  gap: 6.13px !important;
}


.gap-18px {
  gap: 18px !important;
}

.gap-25px {
  gap: 25px !important;
}

.gap-40px {
  gap: 40px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-1px {
  margin-left: 1px !important;
}

.ml-2px {
  margin-left: 2px !important;
}

.ml-5px {
  margin-left: 5px !important;
}

.ml-6px {
  margin-left: 6px !important;
}

.ml-7px {
  margin-left: 7px !important;
}

.ml-9px {
  margin-left: 9px !important;
}

.ml-10px {
  margin-left: 10px !important;
}

.ml-13px {
  margin-left: 13px !important;
}

.ml-15px {
  margin-left: 15px !important;
}

.ml-20px {
  margin-left: 20px !important;
}

.ml-24px {
  margin-left: 24px !important;
}

.ml-25px {
  margin-left: 25px !important;
}

.ml-56px {
  margin-left: 56px !important;
}

.mr-5px {
  margin-right: 5px !important;
}

.mr-8px {
  margin-right: 8px !important;
}

.mr-10px {
  margin-right: 10px !important;
}

.mr-15px {
  margin-right: 15px !important;
}

.mr-20px {
  margin-right: 20px !important;
}

.mr-30px {
  margin-right: 30px !important;
}

.mr-35px {
  margin-right: 35px !important;
}

.mr-114px {
  margin-right: 114px !important;
}

.mr-137px {
  margin-right: 137px !important;
}

.mr-130px {
  margin-right: 130px !important;
}

.tip-preset-label {
  min-width: 85px !important;
  text-align: end !important;
}

.min-height-23px {
  min-height: 23px !important;
}

.min-height-33px {
  min-height: 33px !important;
}

.min-height-60px {
  min-height: 60px !important;
}

.min-height-29vh {
  min-height: 29vh !important;
}

.min-height-55vh {
  min-height: 55vh !important;
}

.min-height-66vh {
  min-height: 66vh !important;
}

.min-height-127px {
  min-height: 127px !important;
}

.min-height-130px {
  min-height: 130px !important;
}

.min-height-200px {
  min-height: 200px !important;
}

.min-height-250px {
  min-height: 250px !important;
}

.min-height-420px {
  min-height: 420px !important;
}

.min-width-0px {
  min-width: 0px !important;
}

.min-width-50px {
  min-width: 50px !important;
}

.min-width-70px {
  min-width: 70px !important;
}

.min-width-90px {
  min-width: 90px !important;
}

.min-width-110px {
  min-width: 110px !important;
}

.min-width-125px {
  min-width: 125px !important;
}

.min-width-140px {
  min-width: 140px !important;
}

.min-width-150px {
  min-width: 150px !important;
}

.min-width-165px {
  min-width: 165px !important;
}

.min-width-178px {
  min-width: 178px !important;
}

.min-width-180px {
  min-width: 180px !important;
}

.min-width-200px {
  min-width: 200px !important;
}

.min-width-225px {
  min-width: 225px !important;
}

.min-width-245px {
  min-width: 245px !important;
}

.min-width-250px {
  min-width: 250px !important;
}

.min-width-264px {
  min-width: 264px !important;
}

.min-width-350px {
  min-width: 350px !important;
}

.min-width-375px {
  min-width: 375px !important;
}

.min-width-420px {
  min-width: 420px !important;
}

.max-height-26px {
  max-height: 26px !important;
}

.max-height-32px {
  max-height: 32px !important;
}

.max-width-67px {
  max-width: 67px !important;
}

.max-width-75px {
  max-width: 75px !important;
}

.max-height-76px {
  max-height: 76px !important;
}

.max-width-100px {
  max-width: 100px !important;
}

.max-height-100px {
  max-height: 100px !important;
}

.max-height-141px {
  max-height: 141px !important;
}

.max-height-310px {
  max-height: 310px !important;
}

.max-height-600px {
  max-height: 600px !important;
}



.max-width-18px {
  max-width: 18px !important;
}

.max-width-85px {
  max-width: 85px !important;
}

.max-width-125px {
  max-width: 125px !important;
}

.max-width-130px {
  max-width: 130px !important;
}

.max-width-135px {
  max-width: 135px !important;
}

.max-width-142px {
  max-width: 142px !important;
}

.max-width-165px {
  max-width: 165px !important;
}

.max-width-189px {
  max-width: 189px !important;
}

.max-width-200px {
  max-width: 200px !important;
}

.max-width-222px {
  max-width: 222px !important;
}

.max-width-292px {
  max-width: 292px !important;
}

.max-width-320px {
  max-width: 320px !important;
}

.max-width-336px {
  max-width: 336px !important;
}

.max-width-359px {
  max-width: 359px !important;
}

.max-width-443px {
  max-width: 443px !important;
}



.max-w-617px {
  max-width: 617px !important;
}

.max-w-1400px {
  max-width: 1400px !important;
}

.max-w-1488px {
  max-width: 1488px !important;
}

.max-width-47perc {
  max-width: 47% !important;
}

.max-width-49perc {
  max-width: 49% !important;
}

.max-width-100perc {
  max-width: 100% !important;
}

.w-100px {
  width: 100px !important;
}

.w-200px {
  width: 200px !important;
}

.w-48 {
  width: 48% !important;
}

.w-45 {
  width: 45% !important;
}

.w-86 {
  width: 86% !important;
}

.w-100perc {
  width: 100% !important;
}

.w-95perc {
  width: 95% !important;
}

.w-90perc {
  width: 90% !important;
}

.width-82px {
  min-width: 82px !important;
}

.width-244px {
  width: 244px !important;
}

.w-80perc {
  width: 80% !important;
}

.w-70perc {
  width: 70% !important;
}

.w-60perc {
  width: 60% !important;
}

.w-45perc {
  width: 45% !important;
}

.w-50perc {
  width: 50% !important;
}

.w-32perc {
  width: 32% !important;
}

.w-30perc {
  width: 30% !important;
}

.w-23perc {
  width: 23% !important;
}

.w-20perc {
  width: 20% !important;
}

.w-15perc {
  width: 15% !important;
}

.w-10perc {
  width: 10% !important;
}

.w-5perc {
  width: 5% !important;
}

.w-auto {
  width: auto !important;
}

.h-23px {
  height: 23px !important;
}

.h-24px {
  height: 24px !important;
}

.h-28px {
  height: 28px !important;
}

.h-172px {
  height: 172px !important;
}

.h-90vh {
  height: 90vh !important;
}

.font-hover:hover {
  font-weight: 700 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.font-lite {
  font-weight: 400 !important;
}

.font-500 {
  font-weight: 500 !important;
}

.font-600 {
  font-weight: 600 !important;
}

.fontsize-16 {
  font-size: 16px;
  line-height: 19px;
}

.fontsize-reduce9em {
  font-size: 0.85em !important;
}

.fontsize-12 {
  font-size: 12px !important;
}

.fontsize-14 {
  font-size: 14px !important;
}

.fontsize-18 {
  font-size: 18px !important;
}

.fontweight-500 {
  font-weight: 500 !important;
}

.hide {
  display: none !important;
}

.border-none {
  border: none !important;
}

.border-6r {
  background: #ffffff;
  border-radius: 6px !important;
}

.border-8r {
  background: #ffffff;
  border-radius: 8px !important;
}

.border-right {
  border-right: 1px solid #e6e8eb;
}

.border-start-8r {
  border: 1px solid #e6e8eb;
  border-radius: 8px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.border-end-8r {
  border: 1px solid #e6e8eb;
  border-radius: 8px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.br-secondary {
  border: 1px solid #e6e8eb !important;
}

.red-border {
  border: 1px solid red !important;
}

.no-top-border {
  border-top: none !important;
}

.h-two {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #2e2e2e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
  margin: 1px 0px;
  /* border-bottom: 1px solid #878787; */
  border-top: 1px solid #e6e8eb;
}

.h-two-no-border {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #2e2e2e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
  margin: 1px 0px;
}

.h-three {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #2e2e2e !important;
}

.h-three-black {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #000000 !important;
}

.text-data {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #2e2e2e !important;
}

.relative {
  position: relative;
}

.box-padding {
  padding: 18px 40px;
}

.box {
  /* background: #ffffff;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 8px; */
  background: #ffffff;
  border: 1px solid #e6e8eb;
  border-radius: 13px;
  width: 100%;
}

.flex {
  display: flex;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.right-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.right-align {
  justify-content: right !important;
  align-items: right;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.flex-space-col {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.space-evenly {
  display: flex;
  justify-content: space-evenly;
}

.pointer-cursor {
  cursor: pointer !important;
}

.pointer-cursor :hover {
  font-weight: bold !important;
  stroke: #6c757d !important;
  stroke-width: 1 !important;
  opacity: 1 !important;
}

.loader {
  /* position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "transparent",
  border: "none",
  zIndex: 100, */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  z-index: 100;
}

.loader:focus {
  outline: none !important;
}

.spacing-none {
  letter-spacing: 0px !important;
}

/* -------------------Button-------------------------- */

.common-btn {
  min-width: 124px;
  max-height: 32px;
  padding: auto 14px;
  /* padding: 8px 16px 7px 13px; */
  border-radius: 6px !important;
  border: none !important;
  color: #ffffff !important;
  background: #2878fa !important;
  display: flex;
  justify-content: center !important;
  gap: 5px;
  align-items: center !important;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  /* line-height: 14px !important; */
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  text-decoration: none !important;
}

.applyBtn {
  padding: 5px 23px;
  font-style: normal;
  font-size: 12px;
  letter-spacing: 0.5px;
  width: 100%;
}

.inline-btn {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center !important;
  text-transform: uppercase;
  color: #2878fa;
  background: #ffffff;
  border: 1px solid #2878fa;
  border-radius: 6px;
  width: 100%;
  /* max-width: 153px; */
  min-height: 27px;
  display: flex !important;
  justify-content: center !important;
  gap: 8px;
  align-items: center !important;
  text-decoration: none !important;
}

.manage-btn {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center !important;
  color: #2e2e2e;
  background: #ffffff;
  border: 1px solid #e6e8eb;
  border-radius: 6px;
  width: 100%;
  /* max-width: 153px; */
  min-height: 27px;
  display: flex !important;
  justify-content: center !important;
  gap: 14px;
  align-items: center !important;
  text-decoration: none !important;
}

.filter-add-btn {
  color: #2e2e2e;
  font-weight: 600;
  background: #f7f8fa;
  border: 1px solid #e6e8eb;
  border-radius: 8px;
  font-size: 14px;
  line-height: 16px;
  padding: 8px 10px 7px;
  margin-top: 18px;
  text-transform: none !important;
}

.text-no-transform {
  text-transform: none !important;
}

.deactive {
  border: 1px solid #d24343;
  color: #d24343;
  max-width: 155px;
  padding: 8px 11px 7px 11px !important;
}

.deactive:hover {
  color: #ffffff;
  background: #d24343;
  border: 1px solid #d24343;
}

.acc-active {
  border: 1px solid #2cc56f;
  max-width: 155px;
  color: #2cc56f;
  padding: 8px 11px 7px 11px;
}

.acc-active:hover {
  color: #ffffff;
  background: #2cc56f;
  border: 1px solid #2cc56f;
}

.editbutton-bkup {
  min-width: 55px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding-top: 1.4px;
  /* padding: 2.5px 4px 1.5px 4px; */
  /* margin-right: 2px; */
  font-weight: 400;
  font-size: 14px;
  /* line-height: 16px; */
  text-align: center;
  color: #2878fa;
  border-radius: 3px;
  border: 1px solid #2878fa;
  background-color: white;
}

.editbutton {
  min-width: 80px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding-top: 1.4px;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #2878fa;
  border-radius: 5px;
  border: 1px solid #2878fa;
  background-color: white;
}

.editbutton:hover {
  color: white !important;
  background-color: #2878fa;
  fill: white !important;
}

.editbutton:hover img {
  filter: brightness(0) invert(1);
}

.d-btn {
  color: red !important;
  border: 1.2px solid red !important;
  font-weight: 500 !important;
}

.d-btn:hover {
  color: white !important;
  background-color: red !important;
}

.a-btn {
  color: green !important;
  border: 1.2px solid green !important;
  font-weight: 500 !important;
}

.a-btn:hover {
  color: white !important;
  background-color: green !important;
}

.primary {
  color: #fff !important;
  background-color: #2878fa;
  border: 1px solid #ddd;
  font-size: 1rem;
  padding: 10px 22px !important;
  border-radius: 8px;
  transition: transform 450ms;
}

button.primary:hover {
  -ms-transform: scale(1.005) !important;
  -webkit-transform: scale(1.005) !important;
  transform: scale(1.005);
  color: #fff !important;
  background-color: #2375fb;
  padding: 10px 22px !important;
}

.cancelbtn {
  font-family: "Roboto";
  font-style: normal;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  /* text-transform: uppercase; */
  color: #787878;
  background-color: white;
  padding: 7px 22px 5px !important;
  letter-spacing: 0.5px;
}

.editBox {
  background: #f7f8fa;
  border: 1px solid #e6e8eb;
  border-radius: 8px;
  padding: 18px;
}

/* -------------------nav-------------------------- */
.nav-header {
  position: sticky;
  left: 0px;
  top: 0px;
  width: 100% !important;
  height: 32px;
  background: #ffffff;
  border-bottom: 1px solid #e6e8eb;
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  align-items: center;
  z-index: 10;
  padding: 0px 42px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: #7d7e7e;
  user-select: none !important;
}

.nav {
  box-sizing: border-box;
  position: sticky;
  width: 100% !important;
  height: 60px;
  left: 0px;
  top: 32px;
  background: #ffffff;
  /* box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25); */
  border-bottom: 1px solid #e6e8eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  padding: 0px 42px;
  user-select: none !important;
}

.nav ul {
  display: flex;
  justify-content: space-between;
  gap: 32px;
  list-style: none;
  padding-top: 3px;
  padding-bottom: 3px;
  border-left: 1px solid #e6e8eb;
}

.nav li,
.nav-a {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  text-decoration: none !important;
  color: #7d7e7e;
  cursor: pointer;
}

.nav .nav-a:hover {
  color: #2e2e2e;
  cursor: pointer;
}

.nav a:hover {
  color: #2878fa;
}

.loginUserBtn {
  height: 26px;
  padding: 0px 4px 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border: 1px solid #e6e8eb;
  border-radius: 8px;
  color: #2e2e2e !important;
  font-weight: 500 !important;
  font-size: 14px;
}

.active {
  min-width: 110px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f7f8fa;
  border: 1px solid #e6e8eb;
  border-radius: 8px;
  color: #2e2e2e !important;
  font-weight: 600 !important;
}

.active:hover {
  border: 1px solid #2878fa;
}

.active .nav-a {
  color: #2e2e2e !important;
}

.active-link {
  color: #2878fa !important;
}

.nav .icon {
  margin-bottom: 1px;
}

.nav .active .icon {
  color: #2878fa;
}

.menuDropdown {
  position: relative;
  display: inline-block;
}

.menuDropdown-body {
  display: none;
  position: absolute;
  min-width: 160px;
  width: 100%;
  height: 50px;
  top: 19px;
  left: 0px;
  background: transparent !important;
  border-radius: 8px;
}

.menuDropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  width: 100%;
  top: 17px;
  text-align: left;
  padding: 10px 10px 13px 10px;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #ececec;
  /* box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
  rgba(17, 17, 26, 0.05) 0px 4px 12px; */

  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.05);
  border-radius: 7px;
  font-weight: 500 !important;
}

/* Links inside the dropdown */
.menuDropdown-content a {
  padding: 4px 2px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.menuDropdown-content a:hover {
  font-weight: 700 !important;
}

/* Show the dropdown menu on hover */
.menuDropdown:hover .icon {
  transform: rotate(180deg);
}

.menuDropdown:hover .menuDropdown-body {
  display: block;
}

.menuDropdown-body:hover .menuDropdown-content {
  display: block;
}

.menuDropdown:hover .menuDropdown-content {
  display: block;
}

.logout-dropdown {
  top: 17px !important;
  left: -47px;
  min-width: 127px !important;
}

.logout-dropdown-accoutlogin {
  top: 17px !important;
  left: 0px;
  min-width: 127px !important;
}

.link-h {
  font-weight: 500;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #787878 !important;
  padding: 5px 2px;
}

.menuAccPosition {
  top: 18px !important;
  left: -76px !important;
  padding: 0px !important;
  background-color: white;

  min-width: 669px !important;
  width: 100% !important;
}

/* -------------------Header-------------------------- */
.header {
  width: 100% !important;
}

.exportbtn {
  min-width: 67.58px;
  max-width: 150px;
  padding: 6px 10px;
  color: #2e2e2e;
  display: flex;
  justify-content: center !important;
  gap: 5px;
  align-items: center !important;
  transition: transform 450ms;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #e6e8eb;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
}

.exportbtn .icon {
  width: 16.22px;
  height: 16.2px;
  margin-right: 5px;
}

.search {
  min-width: 150px;
  min-height: 32px;
  /* padding: 6px 5px 4px 5px; */
  padding: 2.5px 10px !important;
  width: 208.25px;
  border: 1px solid #e6e8eb;
  border-bottom-right-radius: 6px !important;
  border-top-right-radius: 6px !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  background-color: white;
}

.search::placeholder {
  font-weight: 500;
  font-size: 14px !important;
  line-height: 14px;
  color: #7d7e7e;
  align-items: center;
}

.div-searchIcon {
  padding: 2.7px 7px;
  background: #ffffff;
  border: 1px solid #e6e8eb;
  border-right: none;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}

.div-search {
  position: absolute !important;
  top: 8px !important;
  right: 5px !important;
}

.div-ver-top {
  display: table-cell;
  vertical-align: top !important;
}

/* --------------------filter Section----------------------- */
.filter-box {
  background: #ffffff;
  border: 1px solid #e6e8eb;
  border-bottom: none;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  padding: 20px 20px 20px 27px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 42px;
  user-select: none !important;
}

.filter-next-row {
  background: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 42px;
}

.badgefilter {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ffffff;
  background-color: #2878fa;
  border-radius: 50%;
}
.badgeNotification{  
  position: absolute;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: white;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20px;
  min-width: 20px;
  line-height: 0px;
  top: -5px;
  right: -3px;
  background-color: #d24343;
}

.filtervalue {
  height: 24px;
  background: #f2f2f4;
  border: 1px solid #e6e8eb;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #2e2e2e;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  gap: 7px;
  padding-left: 7px;
  padding-right: 7px;
  cursor: pointer;
  width: 100%;
}

.filter-option {
  position: relative;
  display: inline-block;
  margin-right: 10px !important;
}

.filter-option:hover .filtervalue {
  background-color: #ffffff;
}

.filterApplied {
  border: 1px solid #2878fa;
}

.filterAppliedValue {
  color: #2878fa;
  border-left: 1px solid;
  padding-left: 7px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.filterApplied-no-border {
  color: #2878fa;
  padding-left: 7px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.filter-content-body {
  z-index: 1;
  display: none;
  position: absolute;
  min-width: 160px;
  height: 50px;
  top: 20px;
  left: -2px;
  background: transparent !important;
  border-radius: 7px;
  width: 100%;
}

.filter-content {
  z-index: 1;
  display: none;
  position: absolute;
  top: 6px;
  left: 3px;
  text-align: left;
  background: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.05);
  border-radius: 7px;
  max-width: 395px;
  min-width: 219px;
}

.filter-option:hover .filter-content-body {
  display: block !important;
}

.filter-content-body:hover .filter-content {
  display: block;
}

.filter-option:hover .filter-content {
  display: block;
}

.filter-content h1 {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 0px;
}

.filter-content label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #2e2e2e;
  margin-bottom: 10px;
}

.filter-content h1,
input,
select {
  text-decoration: none;
  display: block;
}

.filter-account {
  border: 1px solid #e6e8eb !important;
}

.filter-account span:first-child {
  border-right: 1px solid #e1e2e5 !important;
  height: 100%;
  margin: 0 auto;
}

.multiselect {
  max-height: 145px;
  overflow-y: auto;
  overflow-x: hidden;
}

.multiselect::-webkit-scrollbar-track,
.group-dropdown::-webkit-scrollbar-track {
  background-color: rgb(253, 249, 249);
}

.multiselect::-webkit-scrollbar,
.group-dropdown::-webkit-scrollbar {
  width: 7px;
}

.multiselect::-webkit-scrollbar-thumb,
.group-dropdown::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #e6e8eb;
  border: 1px solid #e6e8eb;
}

.filterAppliedFont {
  color: #2878fa !important;
}

/* .multi-input {
  padding: 0px !important;
  min-height: 34px !important;
  margin-top: 0px !important;
  background: white !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 4px;
  text-align: left !important;
  width: 100% !important;
  font-size: 14px;
  height: auto !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
} */
.multi-input-content {
  padding-top: 15px;
  top: 21px;
  left: 2px;
  width: 100%;
  max-width: 500px;
}

.multiselect label input[type="checkbox"] {
  height: auto !important;
  width: auto !important;
  margin: 0px !important;
}

.multi-input-content input {
  padding: 8px !important;
}

.filter-head {
  font-weight: 600;
  font-size: 18px;
  /* line-height: 21px; */
  color: #080d1c;
  text-align: center;
}

.clear-text {
  font-weight: 500;
  font-size: 14px;
  /* line-height: 14px; */
  color: #898989;
  opacity: 0.8;
}

.acc-filter:hover {
  color: #2878fa !important;
  cursor: pointer;
}

.multisel-status-filter:hover {
  color: #2878fa !important;
  cursor: pointer;
}

/* -------------------Table-------------------------- */

.ctable {
  width: 100%;
  user-select: none !important;
}

.table-box {
  background: #ffffff;
  border: 1px solid #e6e8eb;
  border-top: none;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
  padding: 0px 0px 20px 0px;
}

.htable {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: transparent;
  position: absolute;
  top: 0px;
}

.htable .minicol {
  width: 27px;
  background: #f7f7f7;
  border-top: 1px solid #e6e8eb;
  border-bottom: 1px solid #e6e8eb;
  min-height: 38.5px;
}

.ctable thead th {
  padding: 12px 20px 8.5px 0px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #2e2e2e;
  background: #f7f7f7;
  border-top: 1px solid #e6e8eb;
  border-bottom: 1px solid #e6e8eb;
  z-index: 1;
}

.ctable tr:first-child {
  border-bottom: 0px solid #e6e8eb;
}

.ctable tr:nth-child(2) {
  border-top: 1px solid #e6e8eb;
  border-bottom: 1px solid #e6e8eb;
}

.ctable tr {
  border-bottom: 1px solid #e6e8eb;
}

.ctable td {
  padding: 14px 20px 11px 0px !important;
  /* padding: 3px 1px 1px 27px !important; */
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #2e2e2e;
}

.ctable a:link {
  color: #2878fa;
  text-decoration: none;
}

.ctable a:visited {
  color: #2878fa;
  text-decoration: none;
}

.ctable a:hover {
  font-weight: bold;
}

.ctable a:active {
  color: #2878fa;
}

.tel-staff-container {
  position: relative;
  padding: 0px !important;
  /* min-width: 200px !important; */
}

.tel-staff {
  height: 100%;
  width: 100%;
  padding: 0px !important;
  background-color: transparent !important;
}

.tel-input-staff {
  height: 100% !important;
  width: 100% !important;
  position: absolute;
  top: 0;
  background-color: transparent !important;
}

.tel-staff .special-label {
  display: none !important;
}

.tel-staff::placeholder {
  visibility: hidden;
}

.hidesl .special-label {
  display: none !important;
}

.hides1>input:active,
input:focus-visible {
  box-shadow: none !important;
  /* border: 1px solid #e6e8eb !important; */
}

.no-data-row-table {
  text-align: center;
  min-width: 92vw;
  position: absolute;
  margin-bottom: 10px !important;
  min-height: 60px !important;
}

.no-data-tr-table {
  border: none !important;
}

.table-link:hover {
  text-decoration: underline;
  color: #2878fa;
}

.action-menu {
  top: 20px !important;
  left: -27px;
  min-width: 110px !important;
  z-index: 1;
}

.action-menu-150px {
  top: 20px !important;
  left: -27px;
  min-width: 150px !important;
  z-index: 1;
}

.action-menu-165px {
  top: 20px !important;
  left: -27px;
  min-width: 165px !important;
  z-index: 1;
}

.showHotels {
  min-width: 250px;
  max-width: 320px;
  /* cursor: pointer; */
  position: relative;
}

.showHotelList {
  position: absolute;
  left: 0;
  display: none;
  opacity: 0;
  visibility: hidden;
  background-color: white;
  padding: 14px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.05);
  border-radius: 7px;
  border: .3px solid #ececec;
  z-index: 1;
  max-height: 200px;
}

.showHotels:hover .showHotelList {
  opacity: 1;
  visibility: visible;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

/* -------------------Pagination-------------------------- */

.pagination li button {
  background-color: white;
  font-weight: 400;
  font-size: 14px;
  color: #2e2e2e;
  border: none;
}

.pagination li:first-child button {
  color: #2e2e2e !important;
  background: #ffffff;
  border: 1px solid #e6e8eb;
  border-radius: 6px !important;
}

.pagination li:last-child button {
  color: #2e2e2e !important;
  background: #ffffff;
  border: 1px solid #e6e8eb;
  border-radius: 6px !important;
}

.pagination .Mui-selected {
  border: 1px solid #e6e8eb !important;
  background-color: #f2f2f4 !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #2878fa !important;
}

.num-input {
  max-width: 50px !important;
  max-height: 28px;
  background: #ffffff;
  border: 1px solid #e6e8eb;
  border-radius: 6px;
  padding-right: 2px;
}

/* -------------------Transation-------------------------- */
.transaction {
  display: flex;
  justify-content: flex-start;
  gap: 25px;
}

.transaction h1 {
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #000000;
  opacity: 0.5;
  display: none;
}

.transaction-box {
  background: #ffffff;
  border: 1px solid #e6e8eb;
  border-radius: 9px;
  padding: 12px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  align-items: center;
  max-width: 427px;
  color: #080d1c;
}

.transaction-box div:first-child {
  border-bottom: 1px solid #e6e8eb;
  padding-bottom: 6px;
  margin-bottom: 12px;
}

.transaction-box p {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #7d7e7e;
  margin-bottom: 0px;
}

/* -------------------footer-------------------------- */

.footer {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #898989;
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 93%;
}

/* -------------------- LOGIN ------------------------*/

img.medium {
  width: 100%;
  max-width: 8rem;
}

img.large {
  max-width: 700px;
  width: 100%;
  min-width: 456px;
}

.login {
  color: grey;
  width: 100%;
}

.login h1 {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 35px;
}

.login label {
  font-size: 16px !important;
  font-family: "Inter", sans-serif;
  font-weight: 600 !important;
  margin-bottom: 11px;
  letter-spacing: 0.025em;
}

.password-icon {
  position: absolute;
  top: 7px;
  right: 11px;
}

.calendar-icon {
  position: absolute;
  top: 9px;
  right: 11px;
  z-index: 9;
}

/* -------------------- Login ------------------------*/
.loginbox {
  display: flex;
  flex-direction: column;
  text-align: center;
  /* max-width: 380px; */
}

.loginbox h1 {
  font-weight: 700;
  font-size: 28px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #000000;
  margin-top: 14px;
  margin-bottom: 9px;
}

.loginbox h2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.4px;
  color: #808080;
  /* margin-bottom: 22px; */
  opacity: 0.95;
}

.forgotpwd-main-header {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.4px;
  color: #808080;
  /* margin-bottom: 22px; */
  opacity: 0.95;
}

.forgotpwd-sub-header {
  font-weight: 600;
  font-size: 24px;
  line-height: 18px;
  letter-spacing: 0.4px;
}

.loginbox p {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  /* opacity: 0.99; */
}

.loginbox input {
  min-width: 380px;
  min-height: 40px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 5px 8px 2px 16px;
}

.loginbox input::placeholder {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #7d7e7e;
}

.loginbox img {
  width: 43.91px;
  height: 39px;
  margin: auto;
}

.loginbox .or {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  color: #a2a2a2;
  /* opacity: 0.5; */
  width: 38px;
  height: 13px;
  position: absolute;
  top: 12px;
  left: 46%;
  background-color: #f7f8fa;
}

.or-line {
  border-top: 1px solid #b9b9b9;
  height: 1px;
  margin-top: 21px;
  margin-bottom: 20px;
}

.loginbox a {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-decoration: underline 1px rgb(0, 0, 0);
  color: #000000;
  text-align: left;
  margin-top: 20px;
  cursor: pointer;
}

/* -------------------- Sign Up ------------------------*/
.stepper {
  position: sticky !important;
  top: 0px;
  left: 0px;
  height: 100vh;
  background-color: white;
  padding-top: 33px;
  border-right: 2px solid #e6e8eb;
  font-weight: 500 !important;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.stepper h1 {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #2e2e2e;
}

.stepper .dot {
  border-radius: 50%;
  background-color: #7d7e7e;
  height: 7px;
  width: 7px;
  z-index: 2;
  margin: auto 11.5px;
}

.stepper .bg-box {
  position: absolute;
  top: 0;
  background: #d9d9d9;
  border-radius: 15px;
  height: 100%;
  min-width: 30px;
}

.stepperbody {
  display: flex;
  flex-direction: column;
  text-align: center;
  min-width: 421px !important;
}

.stepperbody input {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.stepperbody .cinput input:first-child {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-bottom: 0px !important;
}

.stepperbody .cinput input:nth-child(2) {
  border-top: none !important;
  margin-top: 0px !important;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.circle {
  background-color: #3871e0;
  color: white;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  padding: 3px 5px;
}

.verify img {
  margin-top: 52px;
  width: 52px;
  height: 40px;
  background: #dee0e2;
}

.verify h1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
  opacity: 0.9;
  margin-top: 29px;
  margin-bottom: 51px;
}

.verify p {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #000000;
}

.verify button {
  font-weight: 500;
  font-size: 18px;
  color: #3871e0;
  background-color: white;
  border: 1px solid #3871e0;
  border-radius: 6px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.business {
  padding: 41px 70px 25px 50px;
}

.business h1 {
  font-weight: 700;
  font-size: 24px;
  color: #2e2e2e;
}

.business h2 {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: #7d7e7e;
  margin-bottom: 26px;
}

.business h3 {
  font-weight: 700;
  font-size: 18px;
  color: #2e2e2e;
  margin-top: 6px;
  margin-bottom: 12px;
}

.business h4 {
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.01em;
  color: #7d7e7e;
  margin-bottom: 26px;
}

.business .label {
  font-weight: 500;
  font-size: 14px;
  color: #2e2e2e;
  margin-bottom: 5px;
}

.business button {
  background: #3871e0;
  border: 1px solid #3871e0;
  color: white;
  border-radius: 6px;
  width: 49%;
  padding: 5px 10px;
  margin-top: 18px;
}

.business span {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.business .para {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.01em;
  color: #7d7e7e;
}

.business input[type="checkbox"] {
  width: 27px !important;
  height: 27px !important;
  margin-top: 5px;
  background: #ffffff;
  border: 1px solid #d9d9d9 !important;
  border-radius: 4px;
}

.create-user input[type="checkbox"] {
  width: 14px !important;
  height: 14px !important;
}

.ql-container.ql-snow {
  border: 1px solid #e6e8eb !important;
  border-radius: 8px;
  background-color: white;
  padding: 20px 0px;
}

.ql-container.ql-snow .ql-editor {
  max-height: 671px;
  overflow-y: scroll;
  background-color: white;
  padding: 0px 20px;
}

.ql-container .ql-snow .ql-editor::-webkit-scrollbar-track {
  display: none !important;
}

.ql-container.ql-snow .ql-editor::-webkit-scrollbar {
  width: 6px;
}

.ql-container.ql-snow .ql-editor::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #e6e8eb;
  border: 1px solid #e6e8eb;
}

.ql-toolbar {
  border: 1px solid transparent !important;
}

/* -------------------- Account Details ------------------------*/
.account {
  padding: 19px 45px 52px 45px;
  user-select: none !important;
}

.account .copy-cursor {
  user-select: text !important;
  cursor: pointer;
  /* cursor: url('./assets/images/arrow.svg'), auto; */
}

.MuiTooltip-tooltip {
  font-size: 10px !important;
}

.account h1 {
  font-weight: 600;
  font-size: 23px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 0px !important;
}

.tip-input-tip {
  display: flex;
  height: 37.028px;
  width: 100%;
  padding: 0px 12.343px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 7.714px;
  align-self: stretch;
  border-radius: 4.629px;
  background: #f5f5f5;
}

.tip-input-tip3 {
  display: flex;
  height: 37.028px;
  width: 100%;
  max-width: 160px;
  padding: 0px 12.343px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 7.714px;
  align-self: stretch;
  border-radius: 4.629px;
  background: #f5f5f5;
}

.tip-input-tip3::placeholder {
  color: rgba(0, 0, 0, 0.4);
  font-family: "Open Sans", sans-serif;
  font-size: 10.724px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.tip3-label {
  color: #000;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0px;
  font-size: 13.5px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.copybtn {
  min-width: 64px;
  min-height: 27px !important;
  max-height: 27px !important;
  /* padding: 8px 18px 7px 16px; */
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  gap: 4px;
  text-decoration: none;
  text-transform: none !important;
}

.copybtn:hover {
  font-weight: 600;
  color: #000000;
}

.index {
  position: absolute;
  top: 5.5px;
  right: 6px;
  font-weight: 500;
  font-size: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #7d7e7e;
  background: #f9f9f9;
  border: 1px solid #e6e8eb;
  border-radius: 4px;
  padding: 2px 8px;
}

.percentage-input {
  display: flex;
  max-height: 32px;
}

.percentage-input span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-right: none;
  border-radius: 6px 0px 0px 6px;
}

.percentage-input .span-right {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-left: none;
  border-radius: 0px 6px 6px 0px;
  padding-right: 8px;
}

.percentage-input input {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.designImg {
  height: 310px !important;
  width: auto;
}

.payout-hfont {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #7d7e7e;
}

.validFont {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin-top: 4.5px;
  color: #7d7e7e;
}

.group-dropdown {
  color: black;
  letter-spacing: 0.5px;
  /* text-transform: capitalize; */
  font-size: 14px;
  padding: 9px 18px 18px;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 145px;
  background: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.05);
  margin-top: 5px;
}

.group-dropdown span:hover {
  color: #2878fa;
  cursor: pointer;
}

.group-container {
  display: grid;
  gap: 18px;
  grid-template-columns: repeat(auto-fit, minmax(min(206px, 100%), 18px));
}

.groupbox {
  font-weight: 600;
  font-size: 14px;
  color: #2e2e2e;
  display: flex;
  flex-direction: column;
  background: #f7f8fa;
  border: 1px solid #e6e8eb;
  border-radius: 8px;
  max-width: 206px;
}

.sub-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #7d7e7e;
}

.returnAcc {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #2878fa;
}

.manageBtn {
  width: 189px;
  height: 24px;
  padding: 10px 0px 9px;
  background: #ffffff;
  border: 1px solid #2878fa;
  border-radius: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #2878fa;
  text-decoration: none;
}

/* -------------------- Terms & Conditions ------------------------*/

.terms p {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.terms h1 {
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  color: #000000;
}

.ql-toolbar.ql-snow {
  padding: 8px 0px !important;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0px !important;
}

.ql-formats button {
  background: #ffffff !important;
  border: 1px solid #e6e8eb !important;
  border-radius: 6px !important;
  width: 28px !important;
  height: 28px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-right: 12px !important;
}

.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: #7d7e7e !important;
}

.ql-snow .ql-stroke {
  stroke: #7d7e7e !important;
}

.default-terms .ql-container.ql-snow {
  border-radius: 3px;
  padding: 20px 20px 20px 0px;
}

/* ------------------Settings ----------------- */
.dollar {
  position: absolute;
  top: 0px;
  left: 0px;
  background: white;
  border: 1px solid #d9d9d9;
  height: 32px;
  width: 22px;
  text-align: center;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  padding-top: 4px;
}

.input-dollar {
  position: absolute;
  top: 0px;
  left: 0px;
  background: white;
  border: 1px solid #d9d9d9;
  height: 32px;
  width: 22px;
  text-align: center;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  padding-top: 5px;
  font-size: 14px;
}

/* ------------------admin user list----------------- */

input.activeCheckbox {
  width: 20px !important;
  height: 24px !important;
  margin-right: 3px !important;
}

/* ------------------notification----------------- */

/* .rnc__notification {
  width: 500px !important;
  margin-top: 58px !important;
  margin-right: -30px !important;
} */

.rnc__notification {
  width: 900px !important;
}

.defaultNotification {
  position: absolute;
  top: 58px;
  right: 20px;
  font-family: "Roboto";
  font-style: normal;
  max-width: 400px;
  width: 100%;
  /* height: 104px; */
  background: #ffffff;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  display: flex;
  gap: 17px;
  padding: 16px 25px;
}

.defaultNotificationMobile {
  font-family: "Roboto";
  font-style: normal;
  max-width: 400px;
  width: 100%;
  min-width: 359px;
  /* height: 104px; */
  background: #ffffff;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  display: flex;
  gap: 17px;
  padding: 16px 25px;
}

.defaultNotification h1,
.defaultNotificationMobile h1 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
}

.defaultNotification p,
.defaultNotificationMobile p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  margin-bottom: 0px !important;
}

.rnc_bg {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: #1c0b0b29;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.rnc__notification-item {
  /* border: 1px #e0e0e0 solid; */
  /* padding: 15px; */
  /* background: #ffffff; */
  /* border: 1px solid #d9d9d9 !important; */
  /* border-radius: 12px !important;*/
  box-shadow: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.idproof-modal {
  background: white;
  padding: 20px;
  width: 100%;
  /* min-height: 200px; */
  display: flex;
  flex-direction: column;
  border: 1px #e0e0e0 solid;
  border-radius: 12px;
}

.idproof-modal .close {
  position: absolute;
  top: 1px;
  left: 1px;
  color: grey;
  cursor: pointer;
}

.idproof-nter {
  align-self: center;
}

.idproof-margin {
  margin: 10px;
}

.div-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.div-left {
  float: left !important;
}

.div-center-margin-auto {
  align-items: center;
  justify-content: center;
  margin: auto;
}

.div-right {
  float: right !important;
}

.page-heading {
  color: #676767 !important;
  font-weight: bold !important;
  margin-bottom: 20px !important;
  padding-top: 2px;
}

/* ------------------account login----------------- */

.acc-nav {
  height: 60px;
  position: sticky;
  top: 0px;
  width: 100% !important;
  background: #ffffff;
  border-bottom: 1px solid #e6e8eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}

.acc-font {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: #7d7e7e;
}

.acc-nav button {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #2e2e2e;
  background-color: white;
  padding: 6px 10px 4px 10px;
}

.avatar-box {
  background: #f7f8fa;
  border: 1px solid #e6e8eb;
  border-radius: 8px;
  padding: 18px;
  display: flex;
  gap: 21px;
  max-width: 358px;
  max-height: 165px;
  overflow: hidden;
}

.avatar-box .logoImg {
  min-width: 128px !important;
  max-width: 128px !important;
  height: 128px !important;
  background: #ffffff;
  border: 1px solid #e6e8eb;
  border-radius: 8px;
  object-fit: fill;
  overflow: hidden;
}

.uploadbtn {
  background: #ffffff !important;
  border: 1px solid #2878fa !important;
  border-radius: 8px !important;
  height: 29px;
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.uploadbtn span {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center !important;
  text-transform: uppercase;
  color: #2878fa !important;
}

/* ------------------Manage Payment----------------- */

.manage-modal h1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #2e2e2e;
  margin-bottom: 0px !important;
}

.manage-modal h2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #080d1c;
  margin: 0px 0px 0px 0px !important;
}

.subhead {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #7d7e7e;
}

.manage-modal .detail-box {
  width: 100%;
  padding: 6px;
  background: #f7f8fa;
  border: 1px solid #e6e8eb;
  border-radius: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  color: #7d7e7e;
}

.manage-modal .detail-box>div:first-child {
  border-bottom: 1px solid #e6e8eb;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #080d1c;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.status-payment {
  padding-top: 6px;
  border-radius: 6px 6px 0px 0px;
  gap: 6px;
}

.manage-modal .label {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #2e2e2e;
  margin-bottom: 6px;
}

.manage-modal .fgrey {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #7d7e7e;
}

.manage-modal p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #7d7e7e;
}

.slider-parent {
  position: relative;
  text-align: center;
  width: 100%;
}

.logo {
  width: 110px;
  margin: 15px 0px 0px 9px;
}

.logo_maid {
  max-height: 50px;
  margin: 10px;
  margin-left: 20px;
}

.logo_maid_tip {
  max-height: 70px;
  margin-top: 0px;
}

.logo_maid_hotel_form {
  max-height: 300px !important;
  margin: 10px;
  background-color: #fcfcfc;
}

.rangeslider {
  width: 100% !important;
  max-width: 2000px !important;
}

.ml-37px {
  margin-left: 37px;
}

.ml-38px {
  margin-left: 38px;
}

.ml-40px {
  margin-left: 40px;
}

.ml-44px {
  margin-left: 44px;
}

.ml-76px {
  margin-left: 76px;
}

.ml-m3px {
  margin-left: -3px;
}

.mrl-10px {
  margin: 0px 10px;
}

.mrl-5px {
  margin: 0px 5px;
}

.inactive-line-light {
  color: #E6E8EB;
}

.inactive-text-light {
  color: #2E2E2E6B;
}

.inactive-bg {
  background-color: #E6E8EB !important
}

.button-export {
  width: 107px;
  height: 29px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--Blue, #2878FA);
  background: var(--Blue, #2878FA);
  display: flex;
  justify-content: center;
  color: var(--White, #FFF);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
  letter-spacing: 0.14px;
  align-items: center;
  gap: 10px;
}

.dashboard-header-box {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  background: #ffffff;
  overflow: hidden;
}

.dashboard-header-text-box {
  height: 30px;
  border: 1px solid #e6e8eb;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #2E2E2E;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.border-right-0 {
  border-right: 0px !important;
}

.border-radius-0 {
  border-radius: 0px !important;
}

.border-left-box {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.border-middle-box {
  border-radius: 0px !important;
}

.border-right-box {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.dashboard-header-text-box-selected {
  border: 1px solid #2878FA;
  background: #2878FA;
  color: #ffffff;
}

.dashBoard-chart-filter-text {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #898989;
  cursor: pointer;
}

.dashBoard-chart-filter-active {
  color: #2878FA;
  border-bottom: solid 2px #2878FA;
}

.dashBoard-data-box {
  background: #ffffff;
  border: 1px solid #e6e8eb;
  border-radius: 9px;
  padding: 12px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  align-items: center;
  max-width: 427px;
  color: #080d1c;
}

.dashBoard-sub-header {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #7D7E7E;
  text-transform: uppercase;
}

.dashBoard-volume-data {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #2878FA;
}

.dashBoard-chart-type-box {
  background: #ffffff;
  border: 1px solid #D9D9D9;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #2E2E2E;
  display: flex;
  cursor: pointer;
  max-width: 100px;
}

.dashBoard-chart-type-item {
  height: 29px;
  padding-top: 2px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
}

.dashBoard-chart-type-right-border {
  border-right: 1px solid #D9D9D9;
}

.dashBoard-chart-type-left-border {
  border-left: 1px solid #D9D9D9;
}

.dashBoard-chart-type-box-selected {
  background: #F7F8FA;
}

.dashBoard-green-text {
  color: #60D777;
}

.dashBoard-percentage-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.dashBoard-percentage-green {
  color: #60D777;
}

.dashBoard-percentage-red {
  color: #eb1212;
}

.filter-box-chart {
  background: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  user-select: none !important;
}

.chart-filtervalue {
  height: 24px;
  background: #F7F8FA;
  border: 1px solid #E6E8EB;
  border-radius: 8px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #2e2e2e;
  display: flex;
  justify-content: space-between;
  align-items: left;
  text-align: left;
  padding-left: 15px;
  padding-right: 5px;
  padding-top: 4px;
  cursor: pointer;
  min-width: 200px;
  min-height: 30px;
}

.chart-filtervalue .icon-inactive {
  margin-top: -7px !important;
  color: #C8C8CA;
}

.chart-filtervalue .icon {
  margin-top: -7px !important;
  color: #2878FA;
}

/* Rotate icon on hover */
.chart-filtervalue:hover .icon {
  transform: rotate(180deg);
}

.chart-filter-content-body {
  z-index: 1;
  display: none;
  position: absolute;
  min-width: 160px;
  height: 50px;
  top: 52px;
  left: -2px;
  background: transparent !important;
  border-radius: 7px;
  width: 100%;
}

.chart-filter-option {
  position: relative;
  display: inline-block;
}

.chart-filter-option:hover .filtervalue {
  background-color: #ffffff;
}

.chart-filter-option:hover .chart-filter-content-body {
  display: block !important;
}

.chart-filter-content-body:hover .filter-content {
  display: block;
}

.chart-filter-option:hover .filter-content {
  display: block;
}

.create-common-template {
  border-bottom: 1px solid #ececec;
  padding: 14px 40px;
  width: 100%;
  text-align: right;
  align-items: flex-end;
  font-weight: bold;
}

.pl-0px {
  padding-left: 0px !important;
}

.pl-9px {
  padding-left: 9px !important;
}

.pl-22px {
  padding-left: 22px !important;
}

.pl-18px {
  padding-left: 18px !important;
}

.pl-21px {
  padding-left: 21px !important;
}

.pl-25px {
  padding-left: 25px !important;
}

.pl-26px {
  padding-left: 26px !important;
}

.pl-37px {
  padding-left: 37px !important;
}

.pl-40px {
  padding-left: 40px !important;
}

.pl-50px {
  padding-left: 50px !important;
}

.pr-7px {
  padding-right: 7px !important;
}

.pr-10px {
  padding-right: 10px !important;
}

.pr-21px {
  padding-right: 21px !important;
}

.pr-60px {
  padding-right: 60px !important;
}

.pr-m50px {
  padding-right: -50px !important;
}

.blue-border {
  border: #2878fa solid 1px;
}

.maxw1000 {
  max-width: 1000px !important;
}

.tipinfodiv {
  display: flex;
  justify-content: space-between;
}

.tipinfodiv p {
  margin: 0;
}

.header-tip {
  position: sticky;
  width: 100%;
  min-height: 133px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header-box-tip3 {
  position: relative;
  width: 100%;
  /* height: 254px; */
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden !important;
}

.circle-border {
  border: 2px solid white;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 150px;
  height: 150px;
}

.circle-bottom {
  position: absolute;
  bottom: -324px;
  width: 494px;
  background-color: white;
  height: 400px;
  border-radius: 100%;
}

.circle-body {
  border-radius: 100%;
  padding: 5px;
  background-color: white;
  width: 140px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.circle-image {
  width: 100%;
  height: 100%;
  max-width: 80px;
  max-height: 80px;
  object-fit: contain;
}

.max-width-500 {
  max-width: 500px !important;
  width: 100% !important;
}

.max-width-400 {
  max-width: 400px !important;
  width: 100% !important;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.justify-right {
  display: flex;
  justify-content: flex-end;
}

.justify-space-around {
  display: flex;
  justify-content: space-around;
}

.justify-space-between {
  display: flex;
  justify-content: space-between;
}

.justify-left {
  display: flex;
  justify-content: left;
}

.tip-processfee-header {
  font-family: "Roboto" !important;
  font-size: 18px !important;
  font-weight: bold !important;
  color: #414141 !important;
}

.tip-processfee-text {
  font-family: "Roboto" !important;
  font-size: 16px !important;
  color: #414141 !important;
  text-align: center;
}

.tip-processfee-button {
  font-family: "Roboto" !important;
  font-size: 16px !important;
  color: #414141 !important;
  background-color: #eed !important;
}

.terms-close-button {
  font-family: "Roboto" !important;
  font-size: 14px !important;
  font-weight: bold !important;
  color: #414141 !important;
  background-color: #eed !important;
}

.content_container-register {
  padding: 0px 20px 0 20px;
}

.content_container {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*  height: 100vh;  TODO */
  padding: 0px 20px 0 20px;
}

.header-content-container {
  background-color: #f0f0f0;
  padding: 20px 20px;
}

.tipSuccessParentCircle {
  width: 93.5px;
  height: 94px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.4;
  position: absolute;
  top: 1px;
  left: 1px;
  /* z-index: -1; */
}

.tipSuccessCircle {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  border: none;
}

.tipThank-heading {
  font-family: Arial;
  font-size: 24.67px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.tipThank-para {
  font-family: Arial;
  font-size: 12.793px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tipinp {
  text-align: right !important;
}

.margin-topM5 {
  margin-top: -5px !important;
}

.margin-top2 {
  margin-top: 2px !important;
}

.margin-top5 {
  margin-top: 5px !important;
}

.margin-top8 {
  margin-top: 8px !important;
}

.margin-top12 {
  margin-top: 12px !important;
}

.margin-top20 {
  margin-top: 20px !important;
}

.margin-top35 {
  margin-top: 45px !important;
}

.margin-top45 {
  margin-top: 45px !important;
}

.margin-top25 {
  margin-top: 25px !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-normal {
  text-decoration: none !important;
}

.text-normal-white {
  text-decoration: none !important;
}

.text-normal-white :hover {
  text-decoration: none !important;
  color: #ffffff !important;
}

.text-normal-color {
  color: #2e2e2e;
}

.text-12px {
  font-size: 12px !important;
}

.payout-subtotal-label {
  font-size: 12px !important;
  line-height: 14px;
  color: #7d7e7e !important;
}

.payout-subtotal-value {
  font-size: 18px !important;
  line-height: 22px;
  color: #2e2e2e !important;
  font-weight: 700;
}

.payout-fees-value {
  font-size: 14px !important;
  line-height: 22px;
  color: #2e2e2e !important;
  font-weight: 500;
}

.socialIconsinnerDiv {
  font-size: 20px;
}

.hotel-logo-right {
  padding-right: 22px !important;
}

img.small {
  width: 100%;
  max-width: 5rem;
}

img.small-logo {
  width: 100%;
  max-width: 4.8rem;
}

img.medium {
  width: 100%;
  max-width: 8rem;
}

img.large {
  max-width: 700px;
  width: 100%;
}

.rounded-20px {
  border-radius: 20px !important;
}

.input-error {
  border: 1px solid #ec5f60 !important;
}

.padding-bottom-5px {
  padding-bottom: 5px !important;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.tip-terms-checkbox {
  text-decoration: none !important;
  color: #000;
  font-family: "Open Sans", sans-serif;
  font-size: 13.49px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.23px;
}

.red-text {
  color: red !important;
}

.red-border {
  border: 1px solid red !important;
}

.review-button {
  min-height: 62px !important;
}

.profile-button {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 14px !important;
  text-align: center !important;
  text-transform: uppercase !important;
  color: #2878fa !important;
}

.profile-status-text {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;
}

.disabled {
  background-color: #f8f8f8 !important;
}

.disabled-text-color {
  color: #d8d8d8 !important;
}

.managePayment-scroll {
  max-height: 195px;
  overflow-y: scroll;
}

.payReceipt-scroll {
  max-height: 250px;
  overflow-y: scroll;
}

.position-fixed {
  position: fixed !important;
}

/* ################################WebPages####################################### */
/* ------------------------------NavBAr----------------------------------------------- */
.nav-web {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  background: transparent;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Inter", sans-serif !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  z-index: 1000;
}

.nav-web-1 {
  width: 100%;
  max-width: 1400px;
  font-family: "Open Sans", sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  padding: 0px 10px;
}

.header-web {
  display: flex;
  color: white;
  width: 100%;
  height: 80px;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
}

.btn-login {
  background: #ffffff;
  border-radius: 6px;
  align-items: center;
  border: #ffffff;
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: #081336 !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px 18px;
  gap: 4px;
  /* width: 161px; */
  height: 40px;
  border-radius: 6px;
  text-decoration: none;
}

.btn-getStart {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0px 18px;
  gap: 4px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.56);
  border-radius: 6px;
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.01em;
  background-color: transparent;
  color: #ffffff;
}

.items-web {
  display: flex;
  gap: 24px;
  align-items: center;
  list-style: none;
  z-index: 100;
  transition: all 20ms;
  margin-bottom: 0px !important;
}

.items-web li {
  background-color: transparent;
  text-decoration: none !important;
}

.items-web li a {
  text-decoration: none !important;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: white;
}

.items-web .close {
  display: none !important;
}

.open {
  display: none !important;
}

@media (max-width: 768px) {
  .nav-web-1 {
    display: none !important;
  }

  .open {
    display: block !important;
    color: white;
  }

  .items-web {
    position: fixed;
    padding: 3.5rem 1.5rem 0;
    /* background: rgba(255,255,255,.3); */
    background: rgba(46, 43, 43, 0.3);
    backdrop-filter: blur(10px);
    color: #ffffff;
    top: 0;
    right: 0;
    height: 100vh;
    width: 310px;
    display: flex;
    flex-direction: column;
    transform: translateX(100%);
    transition: all 0.5s;
  }

  .items__open {
    transform: translateX(0%);
  }

  .items-web li {
    border-bottom: 1px solid white;
    width: 95%;
    text-align: center;
    padding-bottom: 2rem;
    background: transparent;
  }

  .items-web .close {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    font-size: 1.5rem;
    cursor: pointer;
    background: transparent;
    display: block !important;
  }
}

/* ------------------------------NavBAr----------------------------------------------- */
/* ------------------------------Support----------------------------------------------- */
.frame {
  background: url("../src/assets/images/web-frame2.svg") center center / cover no-repeat;
  /* width: 100% !important; */
  min-height: 481px;
  display: flex;
  justify-content: center;
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.1px;
  color: rgba(255, 255, 255, 0.88);
  text-align: start;
  overflow: hidden !important;
}

.frame h1 {
  font-family: "Inter", sans-serif !important;
  font-weight: 600;
  font-size: 77px;
  line-height: 120%;
  letter-spacing: -0.04em;
  text-transform: capitalize;
  color: #ffffff;
}

.support {
  max-width: 920px !important;
  overflow: hidden !important;
}

.support h2 {
  font-family: "Open Sans", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.01em;
  color: #081336;
  margin-bottom: 0px !important;
}

.support h3 {
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  display: flex;
  align-items: center;
  color: #060720;
}

.support p {
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: rgba(60, 60, 67, 0.85);
}

.support .accordion {
  border: none !important;
  box-shadow: none !important;
}

.MuiAccordion-root:before {
  display: none !important;
}

.support .tab-border {
  border-bottom: 1px solid rgba(6, 7, 32, 0.08) !important;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 32px;
}

/* ------------------------------Support----------------------------------------------- */
/* ------------------------------Web-Footer----------------------------------------------- */
.web-footer {
  /* background: #060720; */
}

.footerBg {
  width: 100vw !important;
  height: 310px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  background: #060720;
  z-index: -1;
}

.footer-section1 {
  font-family: "Open Sans", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  color: rgba(255, 255, 255, 0.56);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  gap: 33px;
}

.footer-section1 h5 {
  font-family: "Open Sans", sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 134%;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  color: #ffffff;
  margin-bottom: 40px;
}

.signup-button {
  font-family: "Open Sans", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 23px;
  gap: 10px;
  background: #ffffff;
  border-radius: 6px;
  border: white;
  text-decoration: none;
}

.contact-btn {
  font-family: "Open Sans", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 23px;
  gap: 10px;
  border: 1px solid #ffffff;
  border-radius: 6px;
  background-color: transparent;
}

.footer-section2 {
  background: #060720;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #1f2a48;
  font-family: "Open Sans", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 175%;
  letter-spacing: -0.02em;
  text-decoration: none;
  color: #8687a0;
}

.footer-section2 a {
  font-family: "Open Sans", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 175%;
  letter-spacing: -0.02em;
  text-decoration: none;
  color: #8687a0;
}

.footer-section2 h5 {
  font-family: "Open Sans", sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin-bottom: 16px;
}

.footer-section2Box {
  border-bottom: 1px solid #252642;
  width: 194px;
  padding-bottom: 24px;
  font-family: "Open Sans", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #ffffff;
}

.border-right-liteblue {
  border-right: 1px solid #252642;
}

.border-top-liteblue {
  border-top: 1px solid #252642;
}

@media screen and (max-width: 768px) {
  .web-footer {
    background-color: #060720;
  }

  .footer-section1 {
    padding: 0px;
  }
}

/* ------------------------------Web-Footer----------------------------------------------- */
/* ------------------------------Web-About----------------------------------------------- */
.about {
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 175%;
  letter-spacing: 0.3px;
  color: #000000;
  border-left: 2.96733px solid #43c9f6;
  padding: 0px 10px 0px 44px;
  margin: 82px 0px 96px 0px;
}

.about h2 {
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 130%;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  color: #081336;
  margin-bottom: 24px !important;
}

.map-b {
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 32px;
  width: 685px;
  height: 407.16px;
}

.border-32r {
  border-radius: 32px;
  border: 1px solid #ecf9fe;
}

.map-frame {
  position: absolute;
  top: -2px;
  left: -2px;
  max-width: 665px;
  max-height: 114px;
  width: 100%;
  height: 100%;
  background: #ecf9fe;
  border-radius: 0px 0px 155px 0px;
  z-index: 1;
}

.map-h1 {
  font-family: "Open Sans", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 23.7688px;
  line-height: 28px;
  letter-spacing: -0.03em;
  background: linear-gradient(180deg, #0bb2ea 0%, #43c9f6 100%), #043b4e;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.map-p {
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 175%;
  letter-spacing: -0.02em;
  color: #6c6c6c;
}

.map-blue-text {
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 175%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #3dbaed;
}

.btn-lite-blue {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 22.5784px;
  gap: 9.41px;
  width: 284.74px;
  height: 45.16px;
  background: linear-gradient(180deg, #0bb2ea 0%, #43c9f6 100%), #043b4e;
  border-radius: 5.6446px;
  border: none;
  font-family: "Open Sans", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

/* ------------------------------Web-About----------------------------------------------- */
/* ------------------------------Web-HomePage----------------------------------------------- */

.home-header {
  background: url("./assets/images/web-home-frame.svg") center center / cover no-repeat;
  /* width: 100% !important; */
  min-height: 665px;
  display: flex;
  justify-content: center;
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.1px;
  color: rgba(255, 255, 255, 0.88);
  text-align: start;
  overflow: hidden !important;
}

.image-headerBox {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.image1 {
  max-height: 533.18px;
  max-width: 664.4px;
}

.image2 {
  max-width: 183px;
  max-height: 384.93px;
  position: absolute;
  bottom: 5px;
  left: -115px;
}

.image3 {
  max-width: 601px;
  max-height: 333.04px;
  position: absolute;
  bottom: 132px;
  left: -22px;
}

.home-header h1 {
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 88px;
  line-height: 110%;
  letter-spacing: -0.04em;
  color: #ffffff;
}

.btn-demo {
  width: 182px;
  height: 40px;
  background: #ffffff;
  border-radius: 6px;
  border: #ffffff;
  text-align: center;
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #081336;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 23px;
  gap: 10px;
  text-decoration: none;
}

.what-tova {
  max-width: 880px;
  width: 100%;
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  color: #081336;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 109px auto 185px;
  line-height: 175%;
  letter-spacing: -0.02em;
}

.what-tova h2 {
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.04em;
  text-transform: capitalize;
  color: #081336;
}

.web-home-contentImg {
  background-color: #ecf8fe;
  padding: 60px 53px;
  border-radius: 30px;
}

.web-home-img {
  max-width: 100%;
  height: auto;
}

.web-mob {
  position: absolute;
  left: 86px;
  width: 100%;
  height: auto;
  max-width: 246px;
  max-height: 507px;
}

.home-feature {
  font-family: "Open Sans", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 175%;
  color: #043b4e;
}

.home-feature-h {
  font-weight: 800;
  font-size: 16px;
  line-height: 175%;
  text-transform: uppercase;
  color: #043b4e;
}

.home-get-h {
  font-family: "Open Sans", sans-serif !important;
  font-style: normal;
  font-size: 48px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  color: #081336;
}

.signup-section {
  min-height: 400px;
  background: #40c8f6;
  border-radius: 32px;
  display: flex;
  padding: 10px;
}

.signup-content {
  width: 509px;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.01em;
  padding: 0px 32px;
}

.border-darkBlue {
  border: 1px solid #081336 !important;
  border-radius: 6px !important;
}

.signup-content h4 {
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  margin-bottom: 23px;
}

.web-slider img {
  width: 35%;
  aspect-ratio: 3/2;
  object-fit: contain;
  mix-blend-mode: color-burn;
}

.additional-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px !important;
  max-width: 1400px !important;
  gap: 40px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0px 0px 120px rgba(0, 0, 0, 0.04);
  border-radius: 32px;
  margin: 82px 0px;
}

.additional-box {
  height: 309px;
  background: rgba(236, 249, 254, 0.4);
  box-shadow: 0px 40px 80px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  /* font-family: "Noto Serif Thai", serif !important; */
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 175%;
  color: #081336;
  padding: 40px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 463px;
}

.additional-box h4 {
  font-family: "Open Sans", sans-serif !important;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: #081336;
  margin-bottom: 16px;
}

.btn-add {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 4px;
  width: 220px;
  height: 30px;
  border-radius: 6px;
  font-family: "Open Sans", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid #43c9f6;
  color: #43c9f6;
  text-decoration: none;
  /* background: linear-gradient(180deg, #0bb2ea 0%, #43c9f6 100%);
  box-sizing: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text; */
}

.web-modal {
  font-family: "Open Sans", sans-serif !important;
  font-style: normal;
  padding: 0px 5px;
  position: relative;
  overflow-y: scroll;
}

.web-modal::-webkit-scrollbar {
  display: none !important;
}

.web-modal input,
.web-modal textarea {
  background: #ffffff;
  border: 0.920635px solid rgba(123, 221, 253, 0.4);
  border-radius: 18.4127px;
  font-weight: 400;
  font-size: 18.4127px;
  line-height: 23px;
  display: flex;
  align-items: center;
  /* text-transform: lowercase; */
  color: #242331;
  max-width: 499.9px;
  width: 100%;
  padding: 30px 25px 27px 25px;
}

.web-modal input::placeholder {
  color: rgba(128, 128, 128, 0.68);
}

.web-modal label {
  font-weight: 700;
  font-size: 18.4127px;
  line-height: 150%;
  text-transform: capitalize;
  color: #242331;
  margin-bottom: 12px;
}

.web-modal p {
  width: 480px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: rgba(8, 19, 54, 0.4);
}

.web-modal h1 {
  font-weight: 700;
  font-size: 48px;
  line-height: 130%;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  color: #081336;
  text-align: center;
}

.responsive-home-section {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1606px) {
  .responsive-home-section {
    display: flex;
    justify-content: flex-start;
    gap: 40px;
  }
}

@media screen and (max-width: 800px) {
  .additional-section {
    padding: 10px !important;
  }

  .image2 {
    max-width: 183px;
    max-height: 384.93px;
    position: absolute;
    bottom: 14%;
    left: 19%;
  }

  .image3 {
    max-width: 658px;
    max-height: 334.04px;
    position: absolute;
    bottom: 78px;
    left: 306px;
  }
}

.web-map-indicator-item {
  position: absolute;
  top: -117px;
  left: -64px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, .3);
  /* font-weight: 300; */
  font-size: 13px;
  width: 170px;
  max-height: 244px;
  padding: 12px 24px 12px 12px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  cursor: context-menu;
  color: #000;
}
.web-map-corner{
  background: #fff;
    -webkit-clip-path: polygon(0 0,50% 100%,100% 0);
    clip-path: polygon(0 0,50% 100%,100% 0);
    content: "";
    height: 12px;
    left: 9px;
    position: absolute;
    top: -6px;
    width: 25px;
}
.map-link{
color: #2878FA;
text-decoration: none;
}
.map-link:hover{
  text-decoration: underline;
}
/* ------------------------------Web-HomePage----------------------------------------------- */
/* ################################ END-WebPages ####################################### */
.tip-preset {
  position: absolute !important;
  top: 83px;
  left: 12px;
  right: 0px;
  padding: 7px;
  height: 155px;
  margin: 0px 10px;
  color: #000000;
}

.tip-preset-slider {
  position: absolute !important;
  top: 301px;
  left: 12px;
  right: 0px;
  padding: 7px;
  margin: 0px 20px 0px 25px !important;
  color: #000000;
  width: 220px !important;
}

.tip-preset-head {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: #000000;
}

.tip-preset-text {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #414141;
}

.value-box-group {
  margin: 2px 0px 10px 0px;
  padding-left: 8px;
  padding-right: 8px;
  height: 38px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
  /* box-shadow: 0px 1px 2px 3px rgb(60 64 67 / 8%); */
  border-radius: 7px;
  font-size: 18px;
  font-weight: 500;
}

.hex-code-box-disabled {
  background: #e0e0e0;
  display: flex;
  width: 144px;
  height: 40px;
  margin-bottom: 25px;
  position: relative;
}

.hex-code-box {
  background: #ffffff;
  display: flex;
  width: 144px;
  height: 40px;
  margin-bottom: 25px;
  position: relative;
}

.hexcode-edit-icon {
  border-radius: 6px 0px 0px 6px;
  border: 1px solid #e6e8eb;
  border-right: none;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hexcode-edit-icon:hover .sketchPicker {
  display: block;
}

.hex-code-box:hover .sketchPicker {
  display: block;
}

.hexcode-input {
  width: 100%;
  max-width: 104px;
  border: 1px solid #e6e8eb;
  border-radius: 0px 6px 6px 0px;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #7d7e7e;
}

.sketchPicker {
  position: absolute !important;
  top: 39px;
  left: 0px;
  z-index: 10;
  display: none;
}

/* -------------Image Box start-------------------- */

.drop-file-input {
  position: relative;
  width: 239px;
  height: 218px;
  border: 1px solid #e6e8eb;
  border-radius: 9px;
  text-align: center;
  z-index: 1 !important;
  background-color: white;
  font-weight: 600;
  font-size: 16px;
  line-height: 18.75px;
  align-items: center;
  color: #2e2e2e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.drop-file-input input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 239px;
  height: 218px;
  cursor: pointer;
}

.drop-file-input:hover,
.drop-file-input.dragover {
  opacity: 0.8;
}

.drop-file {
  height: 100%;
  width: 100%;
}

.drop-file-preview {
  position: relative;
  cursor: pointer;
}

.drop-file-preview_img {
  width: 239px;
  height: 218px;
  border-radius: 9px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  /* margin: 3px !important; */
}

.drop-file-preview_img.dragover {
  display: none !important;
}

.drop-file.dragover {
  display: none !important;
}

.drop-file-preview-item-del {
  opacity: 0;
  z-index: 999;
}

.drop-file-preview-item-del button {
  cursor: pointer;
  padding: 8px !important;
  transition: opacity 0.3s ease;
  position: absolute;
  top: -4px;
  right: -19px;
  border-radius: 50px !important;
  background-color: rgb(209, 204, 204);
  box-shadow: 0px 0px 10px rgba(0, 0, 255, 0.3);
  border: none !important;
}

.drop-file-preview-item-del button:hover {
  background-color: rgb(209, 204, 204) !important;
  border: none !important;
  color: rgb(71, 69, 69);
}

.drop-file-preview:hover .drop-file-preview-item-del {
  opacity: 1;
}

.MuiCardActionArea-root {
  padding: 0px !important;
  border: none !important;
}

.ReactCrop__crop-selection {
  border: 1px dashed blue !important;
}

.ReactCrop__drag-handle::after {
  border: 1px solid lightblue !important;
}

.movetoinboxicon {
  margin-top: 70px !important;
  font-size: 42px !important;
  color: #676767 !important;
}

.crophead {
  background-color: #1e3343 !important;
  font-size: 16px !important;
  text-align: center !important;
  color: white !important;
}

.cropbody {
  /* background-color: white !important; */
  padding: 5px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  border-bottom: 1px solid rgb(234, 233, 233);
  height: 450px !important;
  width: 100% !important;
}

.ReactCrop {
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1),
    0 0 0 0.5px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3) !important;
  border-radius: 4px !important;
}

.cropimage {
  max-width: 100% !important;
  min-height: 75px !important;
  max-height: 440px !important;
}

.cropaction {
  padding: 15px !important;
  border: none !important;
  float: right !important;
}

.cropcanvas {
  width: 0px !important;
  height: 0px !important;
  position: absolute !important;
  top: 0px !important;
  z-index: -9 !important;
  opacity: 0 !important;
  visibility: hidden;
}

.drag-btn {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  width: 75%;
  height: 20px;
  border: 1px solid #2878fa;
  border-radius: 8px;
  background-color: #2878fa;
  text-align: center;
  margin-top: 13.5px;
  padding: 2px 2.5px 2px 2px;
}

/* -------------Image Box end-------------------- */

.tipLogoBox {
  position: absolute;
  top: 57px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.tipLogo {
  width: 100%;
  height: auto;
  max-width: 200px !important;
  max-height: 73px !important;
  aspect-ratio: 3/2;
  object-fit: contain;
  /* mix-blend-mode: color-burn; */
  /* filter: brightness(80%) sepia(100%); */
  z-index: 1;
}

.tipLogomain {
  width: 100%;
  height: auto;
  max-width: 219px !important;
  max-height: 143px !important;
  aspect-ratio: 3/2;
  object-fit: contain;
  /* mix-blend-mode: color-burn; */
  /* filter: brightness(80%) sepia(100%); */
  z-index: 1;
}

.tip-option {
  position: absolute;
  top: 193px;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.tip-para {
  font-size: 10.8px;
  font-style: italic;
  line-height: 0px;
  text-align: center;
}

.tip-value {
  font-size: 61.714px;
  font-weight: 700;
  letter-spacing: -2.469px;
  text-transform: uppercase;
  margin-top: 3px;
}

.tip-section {
  display: flex;
  flex-direction: column;
  width: 250px;
  /* justify-content: space-between; */
  align-items: center;
  gap: 6px;
  margin-top: 47px;
}

.tip-box {
  display: flex;
  height: 30.857px;
  width: 58.4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15.428px;
  /* background: #f5f5f5; */
  font-size: 10.8px;
  font-weight: 700;
  line-height: 1px;
}

.tip-option-two {
  position: absolute;
  top: 196px;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.tip-section-two {
  position: absolute;
  bottom: 203px;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  max-width: 250px;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.tip-section-three {
  display: flex;
  flex-direction: column;
  width: 250px;
  /* justify-content: space-between; */
  align-items: center;
  gap: 6px;
  margin-top: 30px;
  margin-left: 13px;
}

.tip-value-three {
  font-size: 55.714px;
  font-weight: 700;
  letter-spacing: -2.469px;
  text-transform: uppercase;
}

.tip-option-three {
  position: absolute;
  top: 258px;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.tipLogoBoxThree {
  position: absolute;
  top: 64px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tipLogoThree {
  width: 100%;
  height: auto;
  max-width: 200px !important;
  max-height: 64px !important;
  aspect-ratio: 3/2;
  object-fit: contain;
  z-index: 1;
  margin: 10px 0px 0px 17px;
}

.tipDescription {
  position: absolute;
  top: 139px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin-left: 5px;
}

.tipDescriptionBox {
  width: 100%;
  height: 100%;
  max-width: 251px;
  max-height: 110px;
  min-height: 96px;
  flex-shrink: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12.315px;
  font-weight: 400;
  border-radius: 9px;
  border: 1.25px solid #f5f5f5;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 6px;
}

.template-room-numLabel {
  position: absolute;
  top: 420px;
  padding-left: 52px;
  font-family: Arial;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  width: 100% !important;
}

.template-room-numLabel-placeholder {
  margin-top: 10px;
  font-family: Arial;
  font-size: 11px;
  font-style: italic;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  background-color: #F5F5F5;
  width: 83% !important;
}

.template-room-numLabel-d2 {
  position: absolute;
  top: 340px;
  padding-left: 46px;
  font-family: Arial;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  display: flex;
  align-items: flex-start;
}

.template-room-numLabel-d2-placeholder {
  margin-top: -8px;
  margin-left: 7px;
  font-family: Arial;
  font-size: 11px;
  font-style: italic;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  background-color: #F5F5F5;
  width: 55%;
}

.template-room-numLabel-d3 {
  position: absolute;
  top: 460px;
  padding-left: 55px;
  font-family: Arial;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  width: 100% !important;
}

.editbuttonReview {
  color: #2878fa;
  text-align: center;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  width: 57px;
  height: 22px;
  /* padding: 2px 5.989px 2px 6px; */
  justify-content: center;
  align-items: center;
  gap: 8.93px;
  flex-shrink: 0;
  border-radius: 3px;
  border: 1px solid #2878fa;
  background-color: white;
}

.editbuttonReview img {
  width: 13.081px;
  height: 15.103px;
  flex-shrink: 0;
}

.reviewchckbox {
  height: 21px !important;
  width: 21px !important;
}

.review-container {
  border-radius: 6px;
  border: 0.5px solid #e6e8eb;
  background: #fff;
  padding: 10px;
}

.review-head {
  /* border-radius: 6px;
  border: 0.5px solid #e6e8eb;
  background: #fff;
  padding: 10px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reviewlogocontainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reviewlogo {
  position: absolute;
  top: 40px;
  width: 100%;
  height: auto;
  max-width: 160px !important;
  max-height: 51px !important;
  aspect-ratio: 3/2;
  object-fit: contain;
  z-index: 1;
}

.review-body {
  border-top: 1px solid #d9d9d9;
  margin: 17px 0px 0px 0px;
  padding: 12px 52px 20px 52px;
}

.copybtn-review {
  min-width: 64px;
  min-height: 30px !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  gap: 4px;
  text-decoration: none;
  text-transform: none !important;
}

.review-logoBox {
  position: relative;
  width: 99.394px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 9px;
  border: 1px solid #e6e8eb;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
}

.review-logoBox input {
  position: absolute;
  top: 0px;
  left: 0px;
  /* visibility: hidden; */
  opacity: 0;
  width: 99.394px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 9px;
  cursor: pointer;
}

.review-drag-btn {
  width: 54px;
  height: 16.697px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #2878fa;
  border: 1px solid #2878fa;
  font-weight: 500;
  color: #fff;
  text-align: center;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.review-input-logo-img {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 99.394px;
  height: 80px;
  border-radius: 9px;
  border: 1px solid #e6e8eb;
  background: #fff;
  object-fit: contain;
  display: flex;
}

.review-input-logo-img:hover {
  display: none !important;
}

.review-logoBox:hover .review-input-logo-img {
  display: none !important;
}

.review-textBox {
  position: absolute;
  top: 102px;
  left: 2px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.review-childTextBox {
  widows: 100%;
  max-width: 190px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 11px;
}

.review-childTextBoxThree {
  widows: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 5px;
  border-radius: 6.318px;
  border: 0.878px solid #f5f5f5;
  min-width: 176.906px;
  max-width: 176.906px;
  height: 70.201px;
  flex-shrink: 0;
  padding: 0px 6px;
}

.review-title-mob {
  text-align: center;
  font-size: 12.577px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  max-width: 160.883px;
}

.review-para-mob {
  font-family: Arial, Helvetica, sans-serif;
  max-width: 137.825px;
  color: #000;
  text-align: center;
  font-size: 7.337px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}

.reviewItems {
  position: absolute;
  top: 195px;
  left: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 198px;
  width: 100%;
}

.reviewItemsBox {
  display: flex;
  justify-content: center;
  gap: 6px;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  max-height: 198px !important;
  max-width: 185px !important;
}

.reviewMainItemsBox {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  /* max-height: 544px !important; */
  max-width: 500px !important;
  margin-bottom: 18px;
}

.reviewItem {
  width: 100%;
  min-height: 69.021px;
  max-height: 99px;
  border-radius: 6px;
  border: 1px solid #e6e8eb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.reviewItemLogo {
  width: 100%;
  height: 100%;
  max-width: 40.504px;
  max-height: 25.455px;
  aspect-ratio: 3/2;
  object-fit: contain;
}

.reviewItemPara-four {
  text-align: center;
  font-size: 9.757px;
  font-weight: 700;
  width: 75.459px;
}

.reviewItemPara {
  text-align: center;
  font-size: 9.757px;
  font-weight: 700;
  width: 100.459px;
}


.reviewMainItem {
  width: 100%;
  min-height: 98.021px;
  max-height: 200px;
  border-radius: 6px;
  border: 1px solid #e6e8eb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 5px;
  padding: 39px 20px;
}

.reviewMainItemPara {
  text-align: center;
  font-family: Arial;
  font-size: 13.899px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.reviewMainItemLogo {
  height: auto;
  max-width: 200px;
  max-height: 80px;
}

.review_bg {
  position: absolute;
  top: 1px;
  left: 0px;
}


.reviewBgcolor {
  position: absolute;
  top: 14px;
  left: 12px;
  height: 90px;
  width: 200px;
  border-radius: 27px 26px 0px 0px;
}

.review-section {
  position: relative;
  /* background-color: #00ba34; */
  margin-left: 12px;
  background-image: url(./assets/images/review-bg1.svg);
  width: 220px;
  height: 459px;
  display: flex;
  flex-direction: column;
}

.receiptHeading {
  color: #000;
  text-align: center;
  font-family: "Open Sans", sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.receiptInput {
  display: flex;
  width: 100%;
  height: 35px;
  padding: 0px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #F5F5F5;
}

.receiptInput::placeholder {
  font-style: italic;
}

.hotel_name {
  font-size: larger;
  font-weight: bold;
  text-align: center;
}

.qrside_label {
  color: #7D7E7E;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.qr-element {
  color: #2E2E2E;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 244px;
  height: 30px;
  background-color: #F7F8FA;
  border: 1px solid #E6E8EB;
  border-radius: 9px;
  display: flex;
  align-items: center;
}

.qr-element-search {
  color: #2E2E2E;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 220px;
  height: 28px;
  background-color: #F7F8FA;
  border: 1px solid #E6E8EB;
  border-radius: 9px;
  display: flex;
  align-items: center;
}

.qr-multi-sel-element {
  color: #2E2E2E;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 32px;
  width: 244px;
  background-color: #F7F8FA;
  border: 1px solid #E6E8EB;
  border-radius: 9px;
  display: flex;
  align-items: center;
}



.qr-nav-input {
  color: #2E2E2E;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  min-width: 100px;
  z-index: 1;
  cursor: pointer;
}

.qr-nav-drop-down {
  position: relative;
  display: flex;
  /* min-width: 245px; */
  align-items: center;
  justify-content: flex-start;
}

.qr-nav-drop-down:hover .qr-extra-space,
.qr-nav-drop-down:hover .dropdown-list {
  display: block !important;
}

.qr-nav-drop-down:hover .dropdown-list {
  display: block !important;
}

.qr-extra-space {
  position: absolute;
  top: 15px;
  width: 100%;
  height: 100px;
  display: none;
}

.dropdown-list ul {
  text-decoration: none;
  list-style-type: none;

}

.dropdown-list {
  z-index: 1;
  display: none;
  position: absolute;
  top: 31px;
  left: 0px;
  text-align: left;
  background: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.05);
  border-radius: 7px;
  /* max-width: 245px; */
  width: 100%;
  min-width: 245px;
  font-weight: 500 !important;
  color: #223342;
  padding: 10px 1px 1px 10px;
}

.qr-dropdown-option {
  cursor: pointer;
  padding: 0px 0px 10px 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #2e2e2e;
}

.qr-dropdown-option:hover {
  color: #2878fa;
}

.qr-nav-content {
  border-radius: 9px;
  border: 1px solid #E6E8EB;
  background: #F7F8FA;
  height: 42px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.order-span-text {
  color: #2E2E2E;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.acc-span-text {
  color: #2E2E2E;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.pool-span-text {
  color: #2878FA;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.qr-main-head {
  border-bottom: 1px solid #ececec;
  padding: 14px 24px;
  width: 100%;
  display: flex;
  gap: 18px;
}

.qr-tab-bkup {
  width: 100%;
  max-width: 421px;
  height: 110px;
  display: flex;
  gap: 18px;
  flex-shrink: 0;
  border-radius: 9px;
  border: 1px solid #E6E8EB;
  background: #F7F8FA;
  cursor: pointer;
}

.qr-tab {
  padding-right: 7px !important;
  max-width: 421px !important;
  height: 110px;
  display: flex;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 9px;
  border: 1px solid #E6E8EB;
  background: #F7F8FA;
  cursor: pointer;
}

.qr-tab-span {
  color: #2E2E2E;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%
}

.qr-main-section {
  border-bottom: 1px solid #ececec;
  padding: 21px 24px;
  width: 100%;
  display: flex;
  gap: 35.5px;
}

.qr-main-span {
  color: #2E2E2E;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.select-none {
  user-select: none !important;
}

.qr-main-section .inactive .icon {
  transform: rotate(90deg);
}

.qr-tab-inactive {
  border: 1px solid #E6E8EB !important;
  background: #EBEBEB !important;
}

.qr-template-card {
  border-radius: 9px;
  border: 1px solid #E6E8EB;
  max-width: 276px;
  max-height: 329.5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.qr-template-img-section {
  position: relative;
  border-radius: 8px 8px 0px 0px;
  background: #F2F2F2;
  width: 275px;
  height: 150px;
  flex-shrink: 0;
  position: relative;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr-template-img {
  width: auto;
  height: auto;
  max-width: 149px;
  max-height: 117px;
}

.qr-template-span-size-text {
  color: #7D7E7E;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.qr-template-span-pay-text {
  color: #7D7E7E;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.qr-template-btn {
  color: #2E2E2E !important; 
  text-decoration: none;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 6px;
  border: 1px solid #E6E8EB;
  background-color: white;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  height: 29px;
  padding: 0px 5px;
}

.qr-template-order-btn {
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 6px;
  border: 1px solid #2878fa;
  background-color: #2878fa;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  height: 29px;
  padding: 0px 5px;
  max-width: 80px !important;
}

.qr-template-add-btn {
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 6px;
  border: none;
  background: #2878fa;
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  max-width: 256px;
  width: 100%;
  height: 29px;
  flex-shrink: 0;
  margin: 0px 0px 15px 0px;
}

.qr-template-more {
  width: 26.548px;
  height: 26.306px;
  flex-shrink: 0;
  display: grid;
  place-items: center;
  cursor: pointer;
  background-color: white;
  border-radius: 6px;
  position: absolute;
  top: 8px;
  right: 9.5px;
  border: 1px solid #E6E8EB;
}

.qr-template-more:hover .icon {
  color: #2878FA !important;
}

.qr-more-dropdown-content {
  top: 7px !important;
  right: 109px !important;
  min-width: 120px !important;
  max-width: 120px !important;
  width: 100% !important;
}

.qr-more-dropdown-text {
  color: #2878FA;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.qr-more-dropdown-text:hover {
  color: #2E2E2E;
}

.qr-continue-btn {
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.16px;
  height: 42px;
  max-width: 269px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2878FA;
  border-radius: 9px;
  border: 1px solid #2878FA;
}

.qr-checkout-nav {
  color: #2E2E2E;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 95px;
  padding: 0px 24px;
  border-bottom: 1px solid #E6E8EB;
}

.text-14px-500-black {
  color: #2E2E2E;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 114.286% */
}

.text-14px-400-black {
  color: #2E2E2E;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* 114.286% */
}

.text-14px-600-black {
  color: #2E2E2E;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 114.286% */
}

.text-12px-600-grey {
  color: #7D7E7E;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.text-12px-600 {
  color: #262626;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.text-12px-400 {
  color: #262626;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-14px-400 {
  color: #262626;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-16px-600-black {
  color: #2E2E2E;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 114.286% */
}

.text-16px-500-black {
  color: #2E2E2E;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 114.286% */
}

.text-16px-400-black {
  color: #2E2E2E;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* 114.286% */
}

.text-12px-700-black {
  color: #000;
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text-8px-400-black {
  color: #000;
  font-family: Arial;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


.text-22px-600-black {
  color: #2E2E2E;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.qr-checkout-head {
  border-bottom: 1px solid #E6E8EB;
  background: #F7F8FA;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0px 24px;
}

.qr-checkout-footer {
  border-top: 1px solid #E6E8EB;
  background: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0px 24px;
}

.qr-cart-footer-text {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #2E2E2E;
}
.qr-cart-footer-text-grey {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.41px;
  text-align: left;
  color: #7D7E7E;
}

.btn-inactive-h36-r6-f16-600 {
  color: #9E9E9E;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background-color: #E6E8EB; 
  border: 1px solid #D6D8DB;
  border-radius: 6px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-blueWhite-h36-r6-f16-600 {
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background-color: #2878FA;
  border: 1px solid #2878FA;
  border-radius: 6px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-whiteBlack-h36-r6-f14-500 {
  color: #2E2E2E;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: white;
  border: 1px solid #E6E8EB;
  border-radius: 6px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-bw {
  color: #2E2E2E !important; 
  background-color: white !important; 
  border: 1px solid #46484B !important; 
}

.selected-qr-card {
  border-radius: 9px;
  border: 1px solid #E6E8EB;
  background-color: white;
  display: flex;
  align-items: center;
  color: #2E2E2E;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.selected-qr-img {
  border-radius: 9px 0px 0px 9px;
  border: 1px solid #E6E8EB;
  background: #F2F2F2;
  width: 110px;
  height: 73px;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected-qr-img img {
  width: auto;
  height: auto;
  max-width: 65px;
  max-height: 65px;
  cursor: pointer;
}

.btn-32-r6-WhiteBlack-left {
  width: 32px;
  height: 32px;
  color: #2E2E2E;
  border-radius: 6px 0px 0px 6px;
  border-left: 1px solid #E6E8EB;
  border-right: 1px solid #E6E8EB;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  background-color: white;
}

.btn-32-r6-WhiteBlack-right {
  width: 32px;
  height: 32px;
  color: #2E2E2E;
  border-radius: 0px 6px 6px 0px;
  border-left: 1px solid #E6E8EB;
  border-right: 1px solid #E6E8EB;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  background-color: white;
}

.btn-left-right {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-top: 1px solid #E6E8EB;
  border-bottom: 1px solid #E6E8EB;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  border-radius: 6px;
}

.MuiStepper-root .Mui-active {
  color: #2878FA !important;
}

.MuiStepper-root .Mui-completed {
  color: #2878FA !important;
}

.MuiStepper-root .MuiStepLabel-label {
  font-weight: 500 !important;
}

.MuiStepper-root .Mui-completed .MuiStepConnector-line {
  border-color: #2878FA !important;
}

.MuiStepper-root .Mui-active .MuiStepConnector-line {
  border-color: #2878FA !important;
}

.btn-qr-apply-text {
  position: absolute;
  top: 3px;
  right: 0px;
  color: #2878FA !important;
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none !important;
  background-color: transparent !important;
  max-width: 84px;
  width: auto !important;
  margin-top: 0px !important;
}

.qr-optional-text {
  position: absolute;
  top: 10px;
  right: 11px;
  color: #7D7E7E !important;
  text-align: right;
  font-style: italic;
  font-weight: 600 !important;
}

.pay-with-card-div {
  border-radius: 0;
  max-width: 500px;
  outline: 0;
  padding: 12px 14px;
  width: 100%;
}

